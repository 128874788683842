@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* Common */

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');*/
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */

:root {
  /* --foreground-color: #f0d04f; */
  --foreground-color: #eed15c;
  --foreground-color-2: #f3f3f3;
  --foreground-color-3: #ccc;
  --background-color: rgb(240, 240, 240);
  /* --background-color: rgb(32, 32, 32); */
  --icon-color: #000;
  --text-color-1: #000;
  --text-color-2: #999;
  --text-color-3: #999;
  --text-highlight-1: #e6ab4d;
  --seperators: #ccc;
}
::selection {
  background-color: #eed15c;
  background-color: var(--foreground-color);
}

svg .cls-1,
svg path.cls-1,
svg .launch-m-nav {
  fill: #eed15c;
  fill: var(--foreground-color);
}

.ab-center {
  display: inline-flex;
  place-self: center;
}

html,
body {
  touch-action: pan-y;
  /* touch-action: none; */
  -webkit-text-size-adjust: none;
}

h1.hide {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  line-height: 0;
  height: 100vh;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  /*background-color: #000;*/
  /* background-image: url('../assets/ui/Leaves.jpg'); */
  background-size: cover;
  font-family: "Balsamiq Sans", cursive;
  /* font-family: 'Fredoka One', cursive; */
  font-weight: 300;
  color: #000;
  color: var(--text-color-1);
}

text {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 0 !important;
  font-family: "Balsamiq Sans", cursive !important;
  font-weight: 300 !important;
  color: #000 !important;
  color: var(--text-color-1) !important;
  text-transform: uppercase;
  text-align: center;
}

/*Portrait Typography*/

@media only screen and (max-aspect-ratio: 1/1) {
  button,
  input,
  textarea {
    font-size: calc(0.45vh + 9px);
    line-height: calc(0.5vh + 17px);
  }

  text.tooltip-text {
    /* font-size: calc(2.5vh + 15px); */
  }

  p {
    /* margin: 0 0 0.5vh 0; */
    margin: 0;
    font-size: calc(0.45vh + 9px);
    line-height: calc(0.5vh + 17px);
    padding: 0 0 0.6vh 0;
  }

  h1 {
    font-size: calc(0.5vh + 9px);
    line-height: calc(0.5vh + 9px);
    padding: 0 0 1vh 0;
  }

  h2 {
    font-size: calc(0.45vh + 9px);
    line-height: calc(0.5vh + 17px);
    padding: 0 0 0.5vh 0;
    /* padding-right: 1vh; */
  }

  h3 {
    margin: 0;
    font-size: calc(0.45vh + 9px);
    line-height: calc(0.5vh + 17px);
    padding: 0 0.5vh 0.5vh 0;
    color: #000;
    color: var(--text-color-1);
    display: inline;
  }

  .menu-items a {
    font-size: calc(0.5vh + 7px);
    border-right: 1px solid #ccc;
    border-right: 1px solid var(--seperators);
  }
}

/*Landscape Typography*/

@media only screen and (min-aspect-ratio: 1/1) {
  button,
  input,
  textarea {
    font-size: calc(0.45vw + 9px);
    line-height: calc(0.5vw + 17px);
  }

  text.tooltip-text {
    /* font-size: calc(2.5vw + 15px); */
  }

  p {
    margin: 0;
    /* margin: 0 0 0.5vw 0; */
    font-size: calc(0.45vw + 9px);
    line-height: calc(0.5vw + 17px);
    padding: 0 0 0.6vw 0;
  }

  h1 {
    font-size: calc(0.5vw + 9px);
    line-height: calc(0.5vw + 9px);
    padding: 0 0 0.7vw 0;
    color: #000;
    color: var(--text-color-1);
  }

  h2 {
    font-size: calc(0.45vw + 9px);
    line-height: calc(0.5vw + 17px);
    padding: 0 0 0.5vw 0;
    /* padding-right: 0.7vw; */
    color: #000;
    color: var(--text-color-1);
  }

  h3 {
    margin: 0;
    font-size: calc(0.45vw + 9px);
    line-height: calc(0.5vw + 17px);
    padding: 0 0.5vw 0.5vw 0;
    color: #000;
    color: var(--text-color-1);
    display: inline;
  }

  .menu-items a {
    font-size: calc(0.5vw + 7px);
    border-right: 1px solid #ccc;
    border-right: 1px solid var(--seperators);
  }
}

h1 {
  /*font-size: 1.8vh;*/
  /*line-height: 1.8vh;*/
  text-align: left;
  /*padding: 0 0 1vh 0;*/
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}

h2 {
  /*font-size: 1.6vh;*/
  /*line-height: 1.6vh;*/
  text-align: left;
  margin: 0;
  /*padding-right: 1vh;*/
  font-weight: 600;
}

.info .category p {
  display: inline;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "Balsamiq Sans", cursive;
  color: #666;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "Balsamiq Sans", cursive;
  color: #666;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "Balsamiq Sans", cursive;
  color: #666;
}

input,
textarea,
input::placeholder,
textarea::placeholder,
button {
  font-family: "Balsamiq Sans", cursive;
  color: #666;
}

input,
textarea,
button {
  color: #666;
}

.infobar h1 {
  align-content: center;
  text-transform: uppercase;
  /*width: 100%;*/
  /*margin-right: 6vh;*/
  margin: 0;
  place-self: center;
  position: relative;
  padding: 0;
  cursor: pointer;
  color: #000;
}

p {
  /*font-size: var(--base-font);*/
  /*line-height: 2.7vh;*/
  text-align: left;
}

.footer p {
  /*font-size: var(--base-font);*/
  line-height: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #999;
  color: var(--text-color-3);
  text-transform: uppercase;
}

a {
  outline: 0 !important;
}

a.logo-home {
  display: contents;
}

.logo {
  z-index: 5;
}

#wrapper {
  position: relative;
  display: grid;
  grid-gap: var(--gap);
  margin: 0 auto;
  padding: var(--gap);
  box-sizing: border-box;
  /*text-align: center;*/
}

.content-contain {
  grid-area: grid;
  box-sizing: border-box;
  /*overflow: hidden;*/
  visibility: hidden;
  display: grid;
  place-content: center;
  position: relative;
  /* z-index: 5; */
}

/* Fix to maintain aspect ratio */
@supports (-webkit-backdrop-filter: blur(1px)) {
  .scale-height svg.maintain-ratio {
    height: intrinsic !important;
  }

  .scale-width svg.maintain-ratio {
    width: intrinsic !important;
  }
}

.portrait .scale-width .ratio-contain,
.portrait-ext .scale-width .ratio-contain {
  position: unset;
}

.logo.go-home {
  display: block;
}

.logo.launch-nav {
  display: none;
  cursor: pointer;
}

.arrows {
  display: none;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  position: absolute;
  display: block;
  width: 100%;
  height: var(--panel);
  top: 50%;
  pointer-events: none;
}

.buttons {
  height: var(--panel);
  max-height: 100%;
  width: var(--panel);
  cursor: pointer;
  pointer-events: all;
  color: #000;
}

button.buttons {
  height: var(--panel);
  max-height: 100%;
  width: var(--panel);
  cursor: pointer;
  pointer-events: all;
  color: #000;
  box-sizing: border-box;
  padding: 0;
}

button.buttons svg {
  /* stroke: 0px !important; */
  padding: 0.28vh;
  box-sizing: border-box;
}

.mockupnav button.buttons svg,
.info button.buttons svg {
  color: #000;
  color: var(--icon-color);
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  /* stroke: 0px !important; */
  padding: 0.28vh;
  box-sizing: border-box;
}

.info button.buttons svg {
  color: #000;
  color: var(--icon-color);
  background-color: #ccc;
  background-color: var(--foreground-color-3);
}

.mockupnav button:hover.buttons svg,
.info button:hover.buttons svg {
  color: #999 !important;
  color: var(--text-color-2) !important;
  stroke: 0px !important;
}

.mockupnav button:focus.buttons svg,
.info button:focus.buttons svg {
  color: #000 !important;
  color: var(--icon-color) !important;
  /* stroke: 0px !important; */
  outline: none;
}

button.buttons.about-content {
  position: absolute;
  bottom: 0;
  right: 0;
}

.info button.buttons {
  position: absolute;
  top: calc(var(--panel) * 3);
  right: calc(var(--panel) * 3);
  display: none;
}

button.buttons svg {
  height: 100%;
  width: 100%;
}

.arrows .nav-left {
  left: 0;
  position: absolute;
}

.arrows .nav-right {
  right: 0;
  position: absolute;
}

.footer {
  grid-area: footer;
  /* background-color: rgb(39, 39, 39); */
  background-color: rgb(250, 250, 250);
  overflow: hidden;
  display: inline-grid;
  /* justify-content: center; */
  /* align-items: center; */
  place-items: center;
  box-sizing: border-box;
  z-index: 5;
}

span.conceal {
  color: rgb(240, 240, 240);
  color: var(--background-color);
}

.footer span.conceal {
  /* color: rgb(39, 39, 39); */
  color: rgb(250, 250, 250);
}

img {
  width: 100%;
  max-width: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

.ratio-contain {
  position: relative;
  overflow: hidden;
  display: inline-flex;
}

.mockup-contain {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  width: 100%;
  padding-top: 82.5%;
  background-color: #f0d04f;
}

.mockup img.mockupImg,
.mockup svg.mockupImg {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  width: 100%;
  pointer-events: none;
}

.grid-item {
  overflow: hidden;
  position: relative;
}

.grid-only {
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
}

.grid-item a.thumbs {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.grid-item img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.content-block {
  display: grid;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "mobilenav";
}

.content-block .info {
  position: relative;
  grid-area: info;
  /*background-color: #f0d04f;*/
  z-index: 10;
  overflow: hidden;
  box-sizing: border-box;
  /* padding: calc(var(--gap) / 4); */
  /* cursor: grab; */
}

.landscape .content-block .portfolio-group .info,
.landscape-ext .content-block .portfolio-group .info,
.portrait .content-block .portfolio-group .info,
.portrait-ext .content-block .portfolio-group .info {
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
}

.content-block .info .info-inner {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: calc(var(--gap) / 4);
  box-sizing: border-box;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content-block .info .info-inner::-webkit-scrollbar {
  display: none;
}

.landscape-ext .content-block .info .info-inner {
  /* padding: var(--gap) var(--gap) calc(var(--gap) / 4) calc(var(--gap) / 4); */
  padding: var(--gap);
}

.landscape .content-block .info .info-inner {
  /* padding: var(--gap) var(--gap) calc(var(--panel) + var(--gap))
    calc(var(--gap) / 4); */
  padding: var(--gap) var(--gap) calc(var(--panel) + var(--gap)) var(--gap);
}

.minimal-landscape .content-block .portfolio-group .info .info-inner,
.minimal-landscape-ext .content-block .portfolio-group .info .info-inner,
.minimal-portrait .content-block .portfolio-group .info .info-inner,
.minimal-portrait-ext .content-block .portfolio-group .info .info-inner {
  /* display: none; */
  background-color: #ccc;
  background-color: var(--foreground-color-3);
  opacity: 1;
  box-sizing: border-box;
  margin: calc(var(--panel) * 3) calc(var(--panel) * 3) 0 calc(var(--panel) * 3);
  border: #ccc solid var(--panel);
  border: var(--foreground-color-3) solid var(--panel);
  position: relative;
  width: calc(100% - calc(var(--panel) * 6));
  height: calc(100% - calc(var(--panel) * 6));
}

/* .minimal-landscape .content-block .portfolio-group .info .info-inner,
.minimal-landscape-ext .content-block .portfolio-group .info .info-inner {
  width: calc(100% - calc(var(--panel) * 6) + calc(var(--panel) / 2) + calc(var(--gap) / 2) );
  margin: calc(var(--panel) * 3) calc(var(--panel) * 3) calc(var(--panel) * 3) calc(calc(var(--panel) * 2) - var(--gap)) ;
} */

.content-block .infobar {
  grid-area: infobar;
  /* background-color: #f0d04f; */
  background-color: #eed15c;
  background-color: var(--foreground-color);
  display: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /*justify-content: end;*/
}

.content-block .mockupnav {
  grid-area: mockup;
  /*grid-gap: 3%;*/
  display: inline-grid;
  grid-auto-flow: column;
  height: var(--panel);
  /*height: 12%;*/
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  z-index: 6;
  align-self: end;
  box-sizing: border-box;
  /*background-color: #f2f2f2;*/
  /*margin-left: 65%;*/
  justify-self: end;
  overflow: hidden;
  justify-content: space-around;
  /*padding: 3%;*/
  /*justify-content: right;*/
  place-content: end;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  /* transform: translateZ(0.01px); */
}

.content-block .close {
  position: absolute;
  right: 0;
  bottom: 0;
  height: var(--panel);
  width: var(--panel);
  z-index: 11;
  align-self: end;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
}

.content-block .folionav {
  grid-area: folionav;
  display: inline-grid;
  grid-auto-flow: column;
  box-sizing: border-box;
  justify-content: space-between;
  /*overflow: hidden;*/
  background-color: #eed15c;
  background-color: var(--foreground-color);
  z-index: 10;
  height: var(--panel);
  /*opacity: .5;*/
}

.content-block .mockup {
  grid-area: mockup;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  position: relative;
  overflow: hidden;
}

.content-block .profile {
  grid-area: profile;
  /*background-color: #f2f2f2;*/
  overflow: hidden;
  box-sizing: border-box;
  /* padding: calc(var(--gap) / 4); */
  grid-row-gap: 0.5vh;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content-block .profile::-webkit-scrollbar {
  display: none;
}

.content-block .skills {
  /*background-color: #f2f2f2;*/
  grid-area: skills;
  display: grid;
  overflow: hidden;
  box-sizing: border-box;
  grid-row-gap: 1vh;
  align-items: center;
  grid-column-gap: var(--gap);
  box-sizing: border-box;
  /* padding: calc(var(--gap) / 4); */
  place-items: start;
}

.content-block .about-all {
  /*background-color: #f2f2f2;*/
  grid-area: profileskills;
  display: grid;
  overflow: hidden;
  box-sizing: border-box;
  grid-row-gap: 0.5vh;
  align-items: center;
  grid-column-gap: var(--gap);
  box-sizing: border-box;
  /* padding: calc(var(--gap) / 4); */
}

.content-block .contact {
  /* grid-area: contact; */
  /* background-color: var(--foreground-color);; */
  overflow: hidden;
  box-sizing: border-box;
  /* padding: calc(var(--gap) / 4); */
  align-content: start;
  grid-row-gap: 0.5vh;
  /* grid-template-rows: auto 1fr; */
  /* display: grid; */
  height: 100%;
  width: 100%;
}

.content-block .contact-inpanel {
  grid-area: contact;
  /*background-color: #f2f2f2;*/
  overflow: hidden;
  box-sizing: border-box;
  padding: calc(var(--gap) / 4);
  align-content: start;
  grid-row-gap: 0.5vh;
  grid-template-rows: auto 1fr;
  display: grid;
}

.content-block .contact-ip-contain {
  /* grid-area: contact; */
  /*background-color: #f2f2f2;*/
  overflow: hidden;
  /* box-sizing: border-box; */
  /* padding: calc(var(--gap) / 4); */
  /* align-content: start; */
  /* grid-row-gap: 0.5vh; */
  /* grid-template-rows: auto 1fr; */
  display: none;
  opacity: 0;
  transform: translateX(-100%);
  z-index: 100;
  position: absolute;
}

.content-block .contact-panel {
  z-index: 99;
  background-color: #000;
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.content-block .nav,
.content-block .mobile-nav,
.content-block .zoom {
  grid-area: mockup;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  /*opacity: .5;*/
  z-index: 10;
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /*outline: 3px solid #000;*/
  /*box-sizing: border-box;*/
}

/* .content-block .nav a,
.content-block .mobile-nav a,
.content-block .zoom a {
  color: #fff;
} */

/*.content-block .zoom .scroll-container {
    box-shadow: 10px 10px 10px 10px black inset;
}*/

.content-block .home-group {
  /*background-color: #f2f2f2;*/
}

.home-group {
  overflow: hidden;
}

.maintain-portrait,
.maintain-landscape,
.maintain-minimal-portrait,
.maintain-minimal-landscape {
  display: none;
}

.portrait-ext .folionav .arrow,
.landscape-ext .folionav .arrow,
.minimal-portrait-ext .folionav .arrow,
.minimal-landscape-ext .folionav .arrow {
  display: block;
}

.portrait .content-contain .arrows,
.portrait-ext .content-contain .arrows,
.landscape-ext .content-contain .arrows,
.minimal-portrait-ext .content-contain .arrows,
.minimal-landscape .content-contain .arrows,
.minimal-landscape-ext .content-contain .arrows {
  display: none;
}

.minimal-portrait .infobar .nav-right,
.minimal-portrait .infobar .nav-left,
.minimal-portrait-ext .infobar .nav-right,
.minimal-portrait-ext .infobar .nav-left {
  display: none;
}

.portrait .maintain-portrait,
.landscape .maintain-landscape,
.minimal-portrait .maintain-minimal-portrait,
.minimal-landscape .maintain-minimal-landscape {
  display: block;
}

.minimal-portrait .infobar .buttons,
.minimal-portrait-ext .infobar .buttons {
  /*width: auto;*/
  /*height: 100%;*/
}

.minimal-landscape .infobar .buttons,
.minimal-landscape-ext .infobar .buttons {
  /*width: 100%;*/
  /*height: auto;*/
}

.minimal-portrait .ratio-contain {
  padding-right: 0px;
  padding-bottom: calc(var(--gap) + var(--panel));
}

.minimal-landscape .ratio-contain {
  padding-right: calc(var(--gap) + var(--panel));
  padding-bottom: 0px;
}

.portrait .ratio-contain {
  padding-right: 0px;
  padding-bottom: calc((var(--gap) * 2) + var(--panel));
}

.portrait-ext .ratio-contain {
  padding-right: 0px;
  padding-bottom: calc((var(--gap) * 2) + var(--panel));
}

.minimal-portrait-ext .ratio-contain {
  padding-bottom: calc(var(--gap) + var(--panel) + var(--gap) + var(--panel));
  padding-right: 0px;
}

.minimal-landscape-ext .ratio-contain {
  padding-right: calc(var(--gap) + var(--panel));
  padding-bottom: calc(var(--gap) + var(--panel));
}

.landscape-ext .ratio-contain {
  padding-right: var(--gap);
  padding-bottom: calc(var(--gap) + var(--panel));
}

.landscape .ratio-contain {
  padding-right: var(--gap);
}

.portfolio-group,
.about-group,
.home-group {
  width: 100%;
  height: 100%;
  display: inline-grid;
  grid-gap: var(--gap);
  /*overflow: hidden;*/
  position: absolute;
  /* background-color: #333; */
  padding: var(--gap);
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
  box-sizing: border-box;
}

.about-group {
  opacity: 0;
}

.home-group {
  opacity: 0;
  padding: 0;
  margin: var(--gap);
  width: calc(100% - var(--gap) * 2);
  height: calc(100% - var(--gap) * 2);
}

.portfolio-group {
  opacity: 0;
  padding: 0;
  background-color: transparent;
}

.grid-group {
  display: inline-grid;
  height: calc(100% - calc(var(--gap) * 2));
  width: calc(100% - calc(var(--gap) * 2));
  grid-gap: var(--gap);
  padding: var(--gap);
  overflow: hidden;
  position: absolute;
  background-color: #eed15c;
  background-color: var(--foreground-color);
  /* transform: translateY(-100%); */
  opacity: 0;
}

.contact-group {
  overflow: hidden;
  opacity: 0;
  padding: var(--gap);
  background-color: #eed15c;
  background-color: var(--foreground-color);
}

.skill-group {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}

.skill-group p.progress-label {
  width: 100%;
  /*font-size: var(--base-font);*/
  /*line-height: var(--base-font);*/
  text-align: left;
  margin: 0;
  align-self: center;
  line-height: calc(0.5vh + 5px);
  height: 60%;
  padding: 0;
  text-indent: calc(var(--gap) / 2);
}

.progress-bar-contain {
  width: 100%;
  /* height: 100%; */
  height: 90%;
  display: grid;
  justify-items: start;
  background-color: #ccc;
}

.progress-bar {
  height: 100%;
  background-color: #eed15c;
  background-color: var(--foreground-color);
  /* animation-name: progress; */
  /* animation-duration: 2s; */
}

@-webkit-keyframes progress {
  from {
    width: 0%;
  }
}

@keyframes progress {
  from {
    width: 0%;
  }
}

.showme {
  display: block !important;
}

.buttons.disabled {
  cursor: auto;
  opacity: 0.2;
}

#placeholder {
  background-color: #ff2d5b;
  height: 1vh;
  width: 1vw;
  position: absolute;
  visibility: hidden;
}

.landscape .content-block .portfolio-group .info,
.landscape-ext .content-block .portfolio-group .info,
.portrait-ext .content-block .portfolio-group .info,
.portrait .content-block .portfolio-group .info {
  display: block !important;
}

/*.portrait-ext .content-block .portfolio-group .info,
.portrait .content-block .portfolio-group .info {
    padding-top: 0;
    padding-bottom: 0;
}*/

.minimal-landscape-ext .content-block .portfolio-group .info .hide,
.minimal-landscape .content-block .portfolio-group .info .hide,
.minimal-portrait-ext .content-block .portfolio-group .info .hide,
.minimal-portrait .content-block .portfolio-group .info .hide {
  display: none !important;
}

/*.minimal-landscape-ext .content-block .portfolio-group .infobar,
.minimal-landscape .content-block .portfolio-group .infobar {
    justify-content: end;
}
*/
.minimal-landscape-ext .content-block .portfolio-group .infobar h1,
.minimal-landscape .content-block .portfolio-group .infobar h1 {
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  -webkit-text-orientation: upright;
          text-orientation: upright;
  position: relative;
}

.minimal-landscape .content-block .portfolio-group .infobar .nav-right {
  place-self: end;
}

.minimal-landscape .content-block .portfolio-group .infobar .nav-left {
  place-self: start;
}

.minimal-landscape-ext .content-block .portfolio-group .infobar .nav-right,
.minimal-landscape-ext .content-block .portfolio-group .infobar .nav-left {
  display: none;
}

/* Menu */

.menu-items {
  text-transform: uppercase;
  font-weight: 300;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 0;
  height: 100%;
  place-items: center;
  z-index: 15;
}

.menu-items a {
  color: #000;
  color: var(--text-color-1);
  /*font-size: var(--base-font);*/
  text-decoration: none;
  width: 100%;
  height: 100%;
  /*border-right: .25vh solid lightgray;*/
  display: inline-grid;
  place-items: center;
  box-sizing: border-box;
}

.menu-items a.active {
  /* color: #ff2d5b; */
  color: #e6ab4d;
  color: var(--text-highlight-1);
}

.menu-items a:last-child {
  border-right: 0px solid;
  border-bottom: 0px solid;
}

.nav {
  /*background-color: var(--background-color);*/
  /*background-color: #ccc;*/
  display: none;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 5;
}

.burger-icon {
  display: block;
  overflow: hidden;
  cursor: pointer;
  z-index: 10;
}

#Burger-H {
  display: none;
}

.settings-Group {
  display: block;
}

.expand {
  display: block;
}

@media only screen and (min-width: 1000px) and (min-aspect-ratio: 1128/688) and (max-aspect-ratio: 1560/690),
  screen and (min-aspect-ratio: 1034/1250) and (max-aspect-ratio: 912/550),
  screen and (min-aspect-ratio: 1640/600) and (max-aspect-ratio: 3000/600),
  screen and (min-aspect-ratio: 768/1100) and (max-aspect-ratio: 1/1) {
  .nav {
    display: grid;
  }

  .burger-icon,
  .content-block .nav {
    display: none !important;
  }

  .settings-Group {
    display: block;
  }

  .expand {
    display: none;
  }
}

@media only screen and (min-aspect-ratio: 338/2000) and (max-aspect-ratio: 338/520) {
  .nav {
    display: none;
  }

  .burger-icon {
    display: none;
  }

  #Burger-H {
    display: block;
  }

  .logo.go-home {
    display: none;
  }

  .logo.launch-nav {
    display: block;
  }

  .footer .hide {
    display: none;
  }
}

@media only screen and (max-height: 500px) {
  .footer .hide {
    display: none;
  }
}

@media only screen and (max-aspect-ratio: 1050/640) and (min-aspect-ratio: 974/642),
  screen and (min-aspect-ratio: 1160/640) and (max-aspect-ratio: 1170/640),
  screen and (min-aspect-ratio: 1514/632) and (max-aspect-ratio: 3000/632) {
  .minimal-portrait .arrows,
  .minimal-landscape .arrows,
  .portrait .arrows,
  .landscape .arrows {
    display: block;
  }

  .minimal-portrait .folionav,
  .minimal-landscape .folionav,
  .portrait .folionav,
  .landscape .folionav {
    display: none !important;
  }

  .landscape .content-block .info .info-inner {
    padding: var(--gap) var(--gap) calc(var(--gap) / 4) calc(var(--gap) / 4);
  }
}

/* Desktop - Landscape */

@media screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 640/390) {
  .main-grid {
    grid-template-columns: 1fr;
    /*grid-template-rows: calc(16vh - var(--gap)) calc(80vh - calc(var(--gap) * 3)) 4vh;*/
    grid-template-rows:
      calc(20vh - var(--gap) - var(--panel)) calc(80vh - calc(var(--gap) * 3))
      var(--panel);
    grid-template-areas: "header" "grid" "footer";
  }

  .logo {
    width: auto;
    grid-area: header;
    justify-self: start;
    text-align: left;
  }

  .logo-h {
    display: block;
    /*height: calc(16vh - 14px);*/
    height: 106%;
    object-fit: contain;
    width: auto;
  }

  .logo-v {
    display: none;
  }

  .nav {
    grid-area: header;
    justify-self: end;
    height: var(--panel);
    width: 45vh;
  }

  .burger-icon {
    grid-area: header;
    justify-self: end;
    box-sizing: border-box;
    width: var(--panel);
    height: var(--panel);
  }
}

/* tablet - Portrait */

@media screen and (min-aspect-ratio: 768/1100) and (max-aspect-ratio: 1/1) {
  .main-grid {
    grid-template-columns: 1fr;
    grid-template-rows:
      calc(10vh - var(--gap) - var(--panel)) calc(90vh - calc(var(--gap) * 3))
      var(--panel);
    grid-template-areas: "header" "grid" "footer";
  }

  .logo {
    width: auto;
    grid-area: header;
    justify-self: start;
    text-align: left;
  }

  .logo-h {
    display: block;
    /*height: calc(16vh - 14px);*/
    height: 106%;
    object-fit: contain;
    width: auto;
  }

  .logo-v {
    display: none;
  }

  .nav {
    grid-area: header;
    justify-self: end;
    height: var(--panel);
    width: 30vh;
  }

  .burger-icon {
    grid-area: header;
    justify-self: end;
    box-sizing: border-box;
    width: var(--panel);
    height: var(--panel);
  }
}

/* Mobile - Landscape */

@media screen and (min-aspect-ratio: 640/390) and (max-aspect-ratio: 1388/508) {
  .main-grid {
    grid-template-columns: 2fr 15fr 2fr;
    grid-template-rows: calc(100vh - calc(var(--gap) * 2));
    grid-template-areas: "logo grid footer";
  }

  .logo {
    width: 100%;
    grid-area: logo;
    justify-self: start;
    text-align: left;
  }

  .logo-h {
    display: none;
    height: auto;
    object-fit: contain;
  }

  .logo-v {
    display: block;
  }

  .nav {
    /*display: grid;*/
    width: 100%;
    height: 20%;
    grid-area: logo;
    align-self: end;
  }

  .menu-items {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .footer {
    padding: var(--gap);
    /* border-top: 1px solid #666; */
    /* border-bottom: 1px solid #666; */
  }

  .footer p {
    line-height: 2vw;
  }

  .menu-items a {
    border-right: 0px solid;
    border-bottom: 1px solid #ccc;
    border-bottom: 1px solid var(--seperators);
  }

  .burger-icon {
    grid-area: logo;
    align-self: end;
    justify-self: start;
    box-sizing: border-box;
    width: var(--panel);
    height: var(--panel);
  }

  .footer p {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    -webkit-text-orientation: upright;
            text-orientation: upright;
    /* align-content: center; */
    /* background-color: var(--foreground-color-2); */
    /* align-items: end; */
    place-content: center;
    /*border-right: .25vh solid lightgray;*/
  }

  /* .footer p {
        line-height: 2vw;
        padding: 10px;
    } */
}

/* Desktop - Landscape - Slim */

@media screen and (min-aspect-ratio: 1388/508) and (max-aspect-ratio: 2320/1) {
  .main-grid {
    grid-template-columns: minmax(0, 1fr) 3fr minmax(0, 1fr);
    grid-template-rows:
      var(--panel) calc(100vh - calc(var(--gap) * 4) - calc(var(--panel) * 2))
      var(--panel);
    grid-template-areas: "logo grid nav" "left grid right" ". grid footer";
  }

  .logo {
    width: 100%;
    grid-area: logo;
  }

  .logo-h {
    display: block;
    height: auto;
    object-fit: contain;
  }

  .logo-v {
    display: none;
  }

  .nav {
    justify-self: center;
    width: 100%;
    grid-area: nav;
    height: 100%;
  }

  .burger-icon {
    justify-self: end;
    box-sizing: border-box;
    width: var(--panel);
    height: var(--panel);
    grid-area: nav;
  }

  .footer .hide {
    display: none;
  }
}

/* Portrait */

@media screen and (min-aspect-ratio: 1/2000) and (max-aspect-ratio: 767/1100) {
  .main-grid {
    grid-template-columns: 1fr;
    grid-template-rows:
      calc(20vh - var(--gap) - var(--panel)) calc(80vh - calc(var(--gap) * 3))
      var(--panel);
    grid-template-areas: "header" "grid" "footer";
  }

  .scale-header {
    grid-template-rows:
      var(--headerh) calc(
        calc(100vh - var(--headerh)) - calc(var(--gap) * 4) - var(--panel)
      )
      var(--panel);
  }

  .logo {
    grid-area: header;
    justify-self: start;
    width: 100%;
    text-align: center;
  }

  .logo-h {
    object-fit: contain;
    display: inline;
    /* height: auto; */
    max-height: 106%;
  }

  .logo-v {
    display: none;
  }

  .nav {
    grid-area: header;
    width: 13%;
    height: auto;
    justify-self: end;
    /*padding: 2vh 2vh;*/
  }

  .menu-items {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .menu-items a {
    border-right: 0px solid;
    border-bottom: 1px solid #ccc;
    border-bottom: 1px solid var(--seperators);
  }

  .burger-icon {
    grid-area: header;
    width: var(--panel);
    height: var(--panel);
    justify-self: end;
  }
}

/*Content blocks*/

/*Minimal Landscape*/

.minimal-landscape .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.minimal-landscape .portfolio-group {
  grid-template-rows: auto;
  grid-template-columns: var(--panel) auto;
  grid-template-areas: "infobar mockup";
}

.minimal-landscape .content-block .portfolio-group .info {
  grid-area: mockup;
  display: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.minimal-landscape .content-block .portfolio-group .infobar {
  display: grid;
  align-items: end;
}

.minimal-landscape .content-block .portfolio-group .folionav {
  display: none;
}

.minimal-landscape .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.minimal-landscape .content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.minimal-landscape .content-block .profile {
  display: block;
}

.minimal-landscape .content-block .skills {
  display: grid;
}

.minimal-landscape .content-block .portfolio-group .info button.buttons {
  display: block;
}

/*Minimal Landscape Extended*/

.minimal-landscape-ext .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.minimal-landscape-ext .portfolio-group {
  grid-template-rows: auto var(--panel);
  grid-template-columns: var(--panel) auto;
  grid-template-areas: "infobar mockup" "folionav folionav";
}

.minimal-landscape-ext .content-block .portfolio-group .info {
  grid-area: mockup;
  display: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.minimal-landscape-ext .content-block .portfolio-group .infobar {
  display: grid;
  align-items: end;
  padding-top: var(--gap);
  padding-bottom: var(--gap);
}

.minimal-landscape-ext .content-block .portfolio-group .folionav {
  display: grid;
}

.minimal-landscape-ext .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.minimal-landscape-ext .content-block .profile {
  display: block;
}

.minimal-landscape-ext .content-block .skills {
  display: grid;
  grid-row-gap: 1.5vh;
}

.minimal-landscape-ext .content-block .skill-group {
  grid-template-columns: 1fr 1fr;
}

.minimal-landscape-ext .content-block .portfolio-group .info button.buttons {
  display: block;
}

/*Landscape*/

.landscape .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.landscape .portfolio-group {
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "mockup info";
}

.landscape .content-block .portfolio-group .info {
  grid-area: info;
  display: block;
}

.landscape .content-block .portfolio-group .infobar {
  display: none;
  align-items: end;
}

.landscape .content-block .portfolio-group .folionav {
  grid-area: info;
  height: var(--panel);
  align-self: end;
  display: none;
}

.landscape .scale-height .content-block .portfolio-group .folionav {
  display: inline-grid;
}

.landscape .scale-height .arrows {
  display: none;
}

.landscape .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.landscape .content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.landscape .content-block .profile {
  display: block;
}

.landscape .content-block .skills {
  display: grid;
}

/*Landscape Extended*/

.landscape-ext .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.landscape-ext .portfolio-group {
  grid-template-rows: auto var(--panel);
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "mockup info" "folionav folionav";
}

/*.landscape-ext .portfolio-group {
    grid-template-rows: auto var(--panel);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "mockup mockup info""folionav folionav folionav";
}*/

.landscape-ext .content-block .portfolio-group .info {
  grid-area: info;
  display: block;
}

.landscape-ext .content-block .portfolio-group .infobar {
  display: none;
  align-items: end;
}

.landscape-ext .content-block .portfolio-group .folionav {
  display: grid;
}

.landscape-ext .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.landscape-ext .content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.landscape-ext .content-block .profile {
  display: block;
}

.landscape-ext .content-block .skills {
  display: grid;
}

/*Portrait*/

.portrait .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.portrait .portfolio-group {
  grid-template-columns: 1fr;
  grid-template-rows: auto 6fr var(--panel);
  grid-template-areas: "mockup" "info" "folionav";
}

.portrait .content-block .portfolio-group .info {
  grid-area: info;
  display: block;
}

.portrait .content-block .portfolio-group .infobar {
  display: none;
  align-items: end;
}

.portrait .content-block .portfolio-group .folionav {
  display: grid;
}

.portrait .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "profile skills";
}

.content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.portrait .content-block .profile {
  display: block;
}

.portrait .content-block .skills {
  display: grid;
  grid-row-gap: 1.5vh;
}

.portrait .content-block .skill-group {
  grid-template-columns: 1fr 1fr;
}

.portrait .content-block .skill-group p.progress-label .too-small {
  display: none;
}

/*Portrait Extended*/

.portrait-ext .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.portrait-ext .portfolio-group {
  grid-template-columns: 1fr;
  grid-template-rows: auto var(--panel) 6fr;
  grid-template-areas: "mockup" "folionav" "info";
}

.portrait-ext .content-block .portfolio-group .info {
  grid-area: info;
  display: block;
}

.portrait-ext .content-block .portfolio-group .infobar {
  display: none;
  align-items: end;
}

.portrait-ext .content-block .portfolio-group .folionav {
  display: grid;
}

.portrait-ext .about-group {
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "profile" "skills";
}

.portrait-ext .content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.portrait-ext .content-block .profile {
  display: block;
}

.portrait-ext .content-block .skills {
  display: grid;
}

.portrait-ext .content-block .skill-group {
  grid-template-columns: 1fr 1fr;
}

.portrait-ext .content-block .skill-group p.progress-label .too-small {
  display: none;
}

/*Minimal Portrait*/

.minimal-portrait .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.minimal-portrait .portfolio-group {
  grid-template-columns: 1fr;
  grid-template-rows: var(--panel) auto;
  grid-template-areas: "infobar" "mockup";
}

.minimal-portrait .content-block .portfolio-group .infobar {
  display: grid;
  align-items: end;
}

.minimal-portrait .content-block .portfolio-group .folionav {
  display: none;
}

.minimal-portrait .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.minimal-portrait .content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.minimal-portrait .content-block .profile {
  display: block;
}

.minimal-portrait .content-block .skills {
  display: grid;
}

.minimal-portrait .content-block .portfolio-group .info {
  grid-area: mockup;
  display: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.minimal-portrait .content-block .portfolio-group .info button.buttons {
  display: block;
}

/*Minimal Portrait Extended*/

.minimal-portrait-ext .home-group {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "home";
}

.minimal-portrait-ext .portfolio-group {
  grid-template-columns: 1fr;
  grid-template-rows: var(--panel) 14fr 1fr;
  grid-template-areas: "infobar" "mockup" "folionav";
}

.minimal-portrait-ext .content-block .portfolio-group .info {
  grid-area: mockup;
  display: none;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.minimal-portrait-ext .content-block .portfolio-group .infobar {
  display: grid;
  align-items: end;
}

.minimal-portrait-ext .content-block .portfolio-group .folionav {
  display: grid;
}

.minimal-portrait-ext .about-group {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "profile skills skills";
}

.content-block .skills {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.minimal-portrait-ext .content-block .profile {
  display: block;
}

.minimal-portrait-ext .content-block .skills {
  display: grid;
  grid-row-gap: 1.3vh;
}

.minimal-portrait-ext .content-block .skill-group {
  grid-template-columns: 1fr 1fr;
}

.minimal-portrait-ext .content-block .portfolio-group .info button.buttons {
  display: block;
}

/*Portfolio Content*/

.desktop-contain,
.tablet-contain,
.mobile-contain {
  position: absolute;
  width: 100%;
  padding-top: 82.5%;
  top: 0;
}

.desktop-contain {
  z-index: 1;
}

.tablet-contain {
  z-index: 2;
}

.mobile-contain {
  z-index: 3;
}

.iframe-contain {
  text-align: center;
  position: absolute;
  /* cursor: grab; */
  /* cursor: -o-grab; */
  /* cursor: -moz-grab; */
  /* cursor: -webkit-grab; */
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  pointer-events: all;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.iframe-contain::-webkit-scrollbar {
  display: none;
}

.mockup-contain:first-child {
  pointer-events: none;
}

.iframe-contain-desk {
  width: 88%;
  height: 74%;
  left: 6%;
  top: 7.2%;
  border-radius: 2%/3%;
}

.iframe-contain-tablet {
  width: 35.4%;
  height: 61.95%;
  left: 4.1%;
  top: 31%;
  border-radius: 5%/3%;
}

.iframe-contain-mobile {
  width: 14.1%;
  height: 38.5%;
  left: 27.75%;
  top: 55%;
  border-radius: 10%/5%;
}

.iframe-contain-zoom {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
}

iframe.iframe-all {
  position: absolute;
  transform-origin: center top;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f2f2f2;
  border: 0;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.iframe-scroll-limit-desk {
  width: 100%;
  overflow: hidden;
}

iframe.iframe-desk {
  width: 1300px;
  height: 1300px;
  /*transform: translateX(-50%) scale(0.542);*/
}

iframe.iframe-tablet {
  width: 768px;
  height: 1200px;
  /*transform: translateX(-50%) scale(0.542);*/
}

iframe.iframe-mobile {
  width: 360px;
  height: 1200px;
  /*transform: translateX(-50%) scale(0.542);*/
}

iframe.iframe-zoom {
  width: 100%;
  height: 100%;
  /*transform: translateX(-50%) scale(0.542);*/
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.animate_op {
  transition: opacity 1s ease-in-out;
  opacity: 1 !important;
}

.web-fg-all {
  position: relative;
}

.web-bg-all {
  overflow: hidden;
  background-repeat: no-repeat;
}

.fg-opt-1 {
  width: 80%;
}

.fg-opt-2 {
  width: 90%;
}

.fg-opt-3 {
  width: auto;
  height: 100%;
}

.fg-opt-4 {
  width: 1042px !important;
}

.bg-opt-1 {
  background-size: 166.3%;
  background-position: center top;
}

.bg-opt-2 {
  background-size: 169%;
  background-position: center -107%;
  background-color: #fff;
}

.bg-opt-3 {
  background-size: 120%;
  background-position: center bottom;
  background-color: #000;
}

.bg-opt-4 {
  background-size: 142%;
  background-position: center bottom;
  background-color: #fff;
}

/*Conatct Form*/

ul {
  margin: 0;
}

input:focus,
textarea:focus {
  /*background-color: #ccc;*/
  outline: 0;
}

form input,
form textarea,
form button {
  width: 100%;
  height: 100%;
  border: 0px solid lightgray;
  background-color: #f3f3f3;
  background-color: var(--foreground-color-2);
  resize: none;
  padding: 1vh;
  box-sizing: border-box;
  text-align: center;
  place-content: center;
  display: inline-grid;
}

form textarea {
  text-align: left;
  padding: calc(var(--gap) / 1);
}

form.contact-f {
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 6fr 1fr 1fr;
  grid-auto-flow: row;
  box-sizing: border-box;
  grid-gap: var(--gap);
  width: 100%;
  height: 100%;
}

form.contact-aut {
  padding: var(--gap);
  position: absolute;
  left: 0;
  top: 0;
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    "aut1 aut1 aut1 aut1 aut2 aut2 aut2 aut2 . . . ."
    ". . . . . . . . . . . .";
  pointer-events: none;
  grid-auto-flow: row;
  box-sizing: border-box;
  grid-gap: var(--gap);
  width: 100%;
  height: 100%;
}

.authorise-1 {
  display: block;
  grid-area: aut1;
  pointer-events: visible;
}

.authorise-2 {
  display: block;
  grid-area: aut2;
  pointer-events: visible;
}

.third-to-full-width,
.third-to-half-width {
  grid-column: span 4;
}

.two-thirds-to-full-width {
  grid-column: span 8;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.half-width {
  grid-column: span 1;
}

.full-width {
  grid-column: span 12;
}

.contact-inpanel form.contact-f,
.portrait form.contact-f,
.portrait-ext form.contact-f {
  grid-template-rows: 1fr 1fr 1fr 6fr 1fr 1fr 1fr;
}

.contact-inpanel form.contact-f.contact-aut,
.portrait form.contact-f.contact-aut,
.portrait-ext form.contact-f.contact-aut {
  grid-template-areas:
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    "aut1 aut1 aut1 aut1 aut1 aut1 aut2 aut2 aut2 aut2 aut2 aut2"
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . .";
}

/* .contact-inpanel .contact form,
.portrait .contact form,
.portrait-ext .contact form {
  grid-template-rows: 1fr 1fr 1fr 6fr 1fr 1fr 1fr;
} */

.contact-inpanel .contact .third-to-full-width,
.portrait .contact .third-to-full-width,
.portrait-ext .contact .third-to-full-width,
.contact-inpanel .contact .two-thirds-to-full-width,
.portrait .contact .two-thirds-to-full-width,
.portrait-ext .contact .two-thirds-to-full-width {
  grid-column: span 12;
}

.contact-inpanel .contact .third-to-half-width,
.portrait .contact .third-to-half-width,
.portrait-ext .contact .third-to-half-width {
  grid-column: span 6;
}

form label {
  display: none;
}

form p {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.arrows button svg {
  background-color: rgb(240, 240, 240) !important;
  background-color: var(--background-color) !important;
  color: #000;
  color: var(--icon-color);
}

.arrows button:hover svg {
  background-color: gold !important;
  color: #000 !important;
}

.arrows button.disabled:hover svg {
  background-color: none !important;
}

button,
input[type="submit"] {
  background-color: transparent;
  width: 100%;
  border: 0;
  box-sizing: border-box;
}

form button,
input[type="submit"] {
  cursor: pointer;
  background-color: #ccc;
  text-transform: uppercase;
}

/* button:hover, */
input[type=submit]:hover
/*,button:focus*/ {
  background-color: #e6ab4d;
  background-color: var(--text-highlight-1);
  outline: 0;
}

button,
input[type="submit"] {
  outline: 0;
}

button,
input,
textarea,
select {
  border-radius: 0;
}

button:hover.disabled
/*,button:focus*/ {
  background-color: transparent;
}

.recaptcha-container {
  display: none;
  position: absolute;
}

/* .tempb {
  z-index: 99999;
  position: absolute;
  background-color: black;
  height: 48px;
  display: block;
  bottom: 20px;
  cursor: pointer;
} */

/* Home-Page */

.target-wrap {
  position: absolute;
  box-sizing: border-box;
}

.menu-dial {
  display: block;
  /* place-self: center; */
  /* width: 100% !important; */
  /* height: 100% !important; */
  z-index: 1;
  /* padding: calc(var(--gap) + calc(var(--panel) / 2)); */
  padding: 0;
  box-sizing: border-box;
  position: relative;
  /* top: 0; */
}

.custom-progress {
  stroke-width: var(--panel);
  stroke: #e6ab4d;
  stroke: var(--text-highlight-1);
  position: absolute;
  box-sizing: border-box;
}

.custom-track {
  stroke-width: var(--panel);
  stroke: #eed15c;
  stroke: var(--foreground-color);
  position: absolute;
  box-sizing: border-box;
}

.custom-thumb {
  r: calc(var(--panel) / 4);
  fill: transparent;
  cursor: -webkit-grab;
  cursor: grab;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
}

.custom-thumb-new {
  cursor: -webkit-grab;
  cursor: grab;
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: var(--panel);
  vector-effect: non-scaling-stroke;
  pointer-events: all;
  position: absolute;
  box-sizing: border-box;
}

.tooltip-G {
  transform-origin: 50% 0;
}

.tooltip-contain {
  transform-origin: 50% 50%;
  box-sizing: border-box;
  padding: calc(var(--panel) + var(--gap));
  position: absolute;
  width: 100%;
  display: flex;
  place-self: center;
  pointer-events: none;
  top: 0;
  right: 0;
}

.Tooltip-Circle {
  fill: none !important;
}

.tooltip-text {
  font-size: 60px;
  fill: #353535;
  letter-spacing: -0.02em;
}

.home-tip .tooltip-text {
  transform: translate(757px, 174px);
}

.work-tip .tooltip-text {
  transform: translate(757px, 174px);
}

.about-tip .tooltip-text {
  transform: translate(755px, 174px);
}

.contact-tip .tooltip-text {
  transform: translate(721px, 174px);
}

.cls-1 {
  font-size: 47px;
  font-family: Assistant-Light, Assistant Light;
  font-weight: 300;
}
.cls-1,
.cls-10 {
  fill: #eed15c;
  fill: var(--foreground-color);
}

.title,
.title-BG {
  position: absolute;
  bottom: 0;
  z-index: 5;
  padding: var(--gap);
  box-sizing: border-box;
  background-color: transparent;
  pointer-events: none;
}

.title-BG {
  z-index: 1;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
}

#Tagline {
  /* transform: scale(0.88); */
  /* transform-origin: 0 5600px; */
  font-size: 41px;
  letter-spacing: 0.1px;
  background-color: rgb(240, 240, 240);
  background-color: var(--background-color);
}

.a6a7b727-3dd8-4a3e-89fc-4e270c6e3421 {
  font-size: 47px;
  /* font-family: Assistant-Light, Assistant Light; */
  font-weight: 300;
}

.skeleton {
  z-index: 2;
  width: 100%;
  position: absolute;
  z-index: 3;
  padding: var(--gap);
  box-sizing: border-box;
  pointer-events: none;
}

.skeleton .cls-1 {
  fill: #fff;
}
.skeleton .cls-2 {
  fill: #f59ba3;
}
.skeleton .cls-3 {
  fill: #f38397;
}
.skeleton .cls-4 {
  fill: #ec5774;
}
.skeleton .cls-5 {
  fill: #b42c20;
}
.skeleton .cls-6 {
  fill: #e43f31;
}
.skeleton .cls-7 {
  fill: #b72b1d;
}
.skeleton .cls-8 {
  fill: #d93a2d;
}

#eyes,
#limbs,
#mouth,
#cheeks,
#eyes-2,
#limbs-2,
#mouth-2,
#cheeks-2 {
  display: none;
}

/* Desktop - Landscape */

@media screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 640/390) {
  .skeleton {
    width: 75%;
    left: 0;
    /* top: 15%; */
  }
  .title,
  .title-BG {
    width: 75%;
    left: 0;
  }

  .target-wrap {
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    padding: calc(var(--panel) / 2 + var(--gap));
  }

  .tooltip-contain {
    height: 100%;
    width: auto;
  }

  .tooltip-G {
    transform: scale(1);
  }

  .home-tip {
    transform: rotate(0deg);
  }

  .work-tip {
    transform: rotate(45deg);
  }

  .about-tip {
    transform: rotate(90deg);
  }

  .contact-tip {
    transform: rotate(135deg);
  }
}

/* Portrait */

@media screen and (min-aspect-ratio: 1/2000) and (max-aspect-ratio: 767/1100) {
  .skeleton {
    width: 80%;
    left: 0;
    bottom: calc(var(--gap) + var(--panel));
    padding: 0;
  }
  .title,
  .title-BG {
    width: 100%;
    left: 0;
  }

  .title-BG {
    z-index: 4;
  }

  .target-wrap {
    right: 0;
    bottom: -5%;
    height: auto;
    width: 150%;
    padding: calc(var(--panel) / 2);
  }

  .tooltip-contain {
    height: 100%;
    width: auto;
    padding: var(--panel);
  }

  .tooltip-G {
    transform: scale(0.7);
  }

  .home-tip {
    transform: rotate(0deg);
  }

  .work-tip {
    transform: rotate(30deg);
  }

  .about-tip {
    transform: rotate(60deg);
  }

  .contact-tip {
    transform: rotate(90deg);
  }
}

/* tablet - Portrait */

@media screen and (min-aspect-ratio: 768/1100) and (max-aspect-ratio: 1/1) {
  .skeleton {
    width: 80%;
    left: 0;
    top: 20%;
    padding: 0;
  }
  .title,
  .title-BG {
    width: 100%;
    left: 0;
  }

  .title-BG {
    z-index: 4;
  }

  .target-wrap {
    right: 0;
    top: 0;
    height: auto;
    width: 150%;
    padding: calc(var(--panel) / 2);
  }

  .tooltip-contain {
    height: 100%;
    width: auto;
    padding: var(--panel);
  }

  .tooltip-G {
    transform: scale(0.5);
  }

  .home-tip {
    transform: rotate(0deg);
  }

  .work-tip {
    transform: rotate(22.5deg);
  }

  .about-tip {
    transform: rotate(45deg);
  }

  .contact-tip {
    transform: rotate(67.5deg);
  }
}

/* Mobile - Landscape */

@media screen and (min-aspect-ratio: 640/390) and (max-aspect-ratio: 1388/508) {
  .skeleton {
    width: 75%;
    left: 0;
  }
  .title,
  .title-BG {
    width: 50%;
    left: 0;
  }

  .target-wrap {
    right: 0;
    top: 0;
    height: 170%;
    width: auto;
    padding: calc(var(--panel) / 2 + var(--gap));
  }

  .tooltip-contain {
    height: 100%;
    width: auto;
  }

  .tooltip-G {
    transform: scale(0.7);
  }

  .home-tip {
    transform: rotate(0deg);
  }

  .work-tip {
    transform: rotate(30deg);
  }

  .about-tip {
    transform: rotate(60deg);
  }

  .contact-tip {
    transform: rotate(90deg);
  }
}

/* Desktop - Landscape - Slim */

@media screen and (min-aspect-ratio: 1388/508) and (max-aspect-ratio: 2320/1) {
  .skeleton {
    width: 75%;
    left: 0;
  }

  .title,
  .title-BG {
    width: 50%;
    left: 0;
  }
  .get-size {
    right: 0;
    top: 0;
    padding-bottom: 150%;
    width: auto;
  }

  .target-wrap {
    right: 0;
    top: 0;
    height: 170%;
    width: auto;
    padding: calc(var(--panel) / 2 + var(--gap));
  }

  .tooltip-contain {
    height: 100%;
    width: auto;
  }

  .tooltip-G {
    transform: scale(0.7);
  }

  .home-tip {
    transform: rotate(0deg);
  }

  .work-tip {
    transform: rotate(30deg);
  }

  .about-tip {
    transform: rotate(60deg);
  }

  .contact-tip {
    transform: rotate(90deg);
  }
}

/* tablet - Portrait */

/* @media screen and (min-aspect-ratio: 768/1100) and (max-aspect-ratio: 1/1) {
  .skeleton {
    width: 55%;
    display: flex;
    place-self: center;
  }
  .title,
  .title-BG {
    width: 100%;
    display: flex;
    place-self: center;
    bottom: 0;
    top: auto;
  }

  .target-wrap {
    height: auto;
    width: 100%;
    padding: calc(var(--panel) / 2 + var(--gap));
    place-self: center;
  }

  .tooltip-contain {
    height: auto;
    width: 100%;
  }

  .tooltip-G {
    transform: scale(1);
  }

  .home-tip {
    transform: rotate(270deg);
  }

  .work-tip {
    transform: rotate(330deg);
  }

  .about-tip {
    transform: rotate(30deg);
  }

  .contact-tip {
    transform: rotate(90deg);
  }
} */

/* Portrait */

/* @media screen and (min-aspect-ratio: 1/2000) and (max-aspect-ratio: 767/1100) {
  .menu-dial {
    transform: rotate(-90deg);
    transform-style: preserve-3d;
  }

  .skeleton {
    width: 55%;
    display: flex;
    place-self: center;
    z-index: 2;
    width: 70%;
    margin-top: 20%;
  }
  .title,
  .title-BG {
    width: 100%;
    top: 57%;
    bottom: auto;
    z-index: 4;
  }
  .title-BG {
    z-index: 3;
  }

  .target-wrap {
    height: auto;
    width: 100%;
    padding: calc(var(--panel) / 2 + var(--gap));
    place-self: center;
  }

  .tooltip-contain {
    height: auto;
    width: 100%;
  }

  .tooltip-G {
    transform: scale(1);
  }

  .home-tip {
    transform: rotate(270deg);
  }

  .work-tip {
    transform: rotate(330deg);
  }

  .about-tip {
    transform: rotate(30deg);
  }

  .contact-tip {
    transform: rotate(90deg);
  }
} */

@media only screen and (max-height: 500px) {
  .landscape .about-group,
  .landscape-ext .about-group {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    grid-template-areas: "profile skills skills";
  }
  .skill-group p.progress-label {
    text-align: center;
    text-indent: none;
  }

  .skill-group p.progress-label .too-small {
    display: none;
  }
}
